import { template as template_b933a3b1c45b493985380f09e918c62c } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_b933a3b1c45b493985380f09e918c62c(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
