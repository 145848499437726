import { template as template_c23a6ea8d7a94b40a234f5c278cec556 } from "@ember/template-compiler";
const SidebarSectionMessage = template_c23a6ea8d7a94b40a234f5c278cec556(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
