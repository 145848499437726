import { template as template_7e8066386c294f3dae932c682e47a999 } from "@ember/template-compiler";
const FKLabel = template_7e8066386c294f3dae932c682e47a999(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
