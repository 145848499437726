import { template as template_148f6bae0d6d45edb484bd46e442a56f } from "@ember/template-compiler";
const WelcomeHeader = template_148f6bae0d6d45edb484bd46e442a56f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
